import React, {Component} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import EventCard from '../../components/eventCard';
import apiTokenService from '../../services/apiToken';
import categoryService from '../../services/category';
import eventService from '../../services/event';
import tagService from '../../services/tag';
import Typography from "@material-ui/core/Typography";
import Loader from 'react-loader-spinner';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as errorMessagesActions from "../../store/errorMessage/actions";
import * as apiTokenActions from "../../store/apiToken/actions";

import ErrorDialog from '../../components/errorDialog';
import config from '../../services/config/config';

import { Api } from "../../services/config/request";
import standardSlugify from "standard-slugify";
import TagManager from 'react-gtm-module'

import './pageView.scss'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as pageViewActions from "../../store/pageView/actions";

class PageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      app_secret: this.props.app_secret,
      city: this.props.city,
      dist: this.props.dist,
      topevents: this.props.topevents,
      input_categories: this.props.categories,
      input_tags: this.props.tags,
      loading: false,
      page: 1,
      maxEvents: 0,
      maxPerPage: 9,
      end: false,
      categories: [],
      params: this.props.params,
      tags: [],
      focused: false,
      focusedEvent: null,
    };
    // getAuthToken();
  }



  componentDidMount() {
    if (!this.state.activeApiToken) {
      apiTokenService.getApiToken(this.state.app_secret)
      .then(awns => {
        this.props.apiTokenActions.setActiveApiToken(awns);
        // this.setState({activeApiToken: awns, errorMessage: "Une Erreur"});
        this.setState({activeApiToken: awns});
        return categoryService.getCategoryList()
      })
      .then(response => {
        const TJcategories =response ? response.rows : [];
        const categories = [];
        // this.setState({
        //   categories: response ? response.rows : []
        // });
        if (this.state.input_categories) {
          JSON.parse(this.state.input_categories).forEach(input_cat => {
            const catId = TJcategories
            .filter(x => input_cat.toUpperCase() === x.name)
            .map(x => x.id);
            if (catId) {
              categories.push(catId[0]);
            }
            // console.log("catId", catId)
          })
        }
        this.setState({categories: categories});
        if (this.state.input_tags) {
          const myImputTags = JSON.parse(this.state.input_tags)
          return tagService.searchTag(myImputTags.join(' '));
        } else {
          return null;
        }
      })
      .then(response => {
        const TJtags =response ? response.rows : [];
        this.setState({ tags: TJtags.map(x => x.id)});

        return eventService.getEventList(this.state.city?this.state.city:null,
          this.state.dist?this.state.dist:null,
          this.state.topevents?this.state.topevents:null,
          this.state.categories.length > 0 ? this.state.categories:null,
          this.state.tags.length > 0 ? this.state.tags:null,
          this.state.maxPerPage, this.state.page
        );
      })
      .then(events => {
        // const events = response.rows.map (x => {
        //   x.image = API_URL + '/TJapi/event/' + x.id + '/getImage';
        //   return x;
        // })
        // console.log(events.rows);
        this.setState({
          events: events.rows,
          maxEvents: events.metaData.count
        });
      })
      .then(() => {
        let url = window.location.pathname;
        Api.getRequest('/auth/validate_token')
        .then(res => {
          let tagManagerArgs = {
            dataLayer: {
              'event': 'generic',
              'app_id': res.user.appId,
              'company_name': standardSlugify(res.user.companyname),
              'environment': process.env.REACT_APP_ENVIRONMENT,
              'eventName': '',
              'pageType': url.split('/')[1]
            },
          }
          TagManager.dataLayer(tagManagerArgs)
          tagManagerArgs = {
            dataLayer: {
              'event': 'pageview',
              'page Path': config.IFRAME_URL + url
            }
          }
          TagManager.dataLayer(tagManagerArgs)
        });
      })
      .catch(err => {
        this.setState({
          events: []
        });
        this.props.errorMessagesActions.setActiveErrorMessage(err.message);
        this.setState({errorMessage: err.message});
        // console.log(err)
      });
    } else {
      categoryService.getCategoryList()
      .then(response => {
        const TJcategories =response ? response.rows : [];
        const categories = [];
        // this.setState({
        //   categories: response ? response.rows : []
        // });
        if (this.state.input_categories) {
          JSON.parse(this.state.input_categories).forEach(input_cat => {
            const catId = TJcategories
            .filter(x => input_cat.toUpperCase() === x.name)
            .map(x => x.id);
            if (catId) {
              categories.push(catId[0]);
            }

            // console.log("catId", catId)
          })
        }
        this.setState({categories: categories});
        if (this.state.input_tags) {
          const myImputTags = JSON.parse(this.state.input_tags)
          return tagService.searchTag(myImputTags.join(' '));
        } else {
          return null;
        }
      })
      .then(response => {
        const TJtags =response ? response.rows : [];
        this.setState({ tags: TJtags.map(x => x.id)});
        return eventService.getEventList(this.state.city?this.state.city:null,
          this.state.dist?this.state.dist:null,
          this.state.topevents?this.state.topevents:null,
          this.state.categories.length > 0 ? this.state.categories: null,
          this.state.tags.length > 0 ? this.state.tags: null,
          this.state.maxPerPage, this.state.page
        );
      })
      .then(events => {
        // const events = response.rows.map (x => {
        //   x.image = API_URL + '/TJapi/event/' + x.id + '/getImage';
        //   return x;
        // })
        // console.log(events.rows);
        this.setState({
          events: events.rows,
          maxEvents: events.metaData.count
        });
      })
      .catch(err => {
        this.props.errorMessagesActions.setActiveErrorMessage(err.message);
        this.setState({'errorMessage': err.message});
      })
    }
  }

  render() {
    const handleClose = () => {
      this.setState({'errorMessage': null});
    }
    let myErrorMessage = null;
    // console.log(store.getState());
    if (this.state.errorMessage) {
      myErrorMessage = (
        <ErrorDialog handleClose={handleClose} errorMessage={this.state.errorMessage} />
      )
    }

    const change_page = (page) => {
      if (page > 0 && this.state.loading === false) {
        if (page < this.state.page)
        // setEventState({...this.state, page: page, end: false});
        this.setState({ page: page, end: false});
        else
        // setEventState({...this.state, page: page});
        this.setState({ page: page});
        fetchEvents(page);
      }
    };

    const handleChange = (t, page) => {
      change_page(page);
    };

    const fetchEvents = (page) => {
      eventService.getEventList(this.state.city?this.state.city:null,
        this.state.dist?this.state.dist:null,
        this.state.topevents?this.state.topevents:null,
        this.state.categories.length > 0 ? this.state.categories:null,
        this.state.tags.length > 0 ? this.state.tags:null,
        this.state.maxPerPage, page
      )
      .then(res => {
        if (res.rows.length <  this.state.maxPerPage)
        this.setState({events: res.rows, loading: false, maxEvents: res.metaData.count, end: true, page: page});
        else
        this.setState({events: res.rows, maxEvents: res.metaData.count, loading: false, page: page});
      })
      .catch(err => {
        this.props.errorMessagesActions.setActiveErrorMessage(err.message);
        this.setState({'errorMessage': err.message});
      });
    }

    const onCardClick = (event) => {
      if (this.props.details) {
        let focusedEvent = (event) ? this.state.events.find(e => e.id === event.id) : null;
        this.setState({focusedEvent: focusedEvent}, () => {
          this.setState({focused: !this.state.focused});
          return false;
        });
      } else
        return false;
    };

    if (this.state.events && this.state.events.length > 0) {
      return (
          <>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: 'wrap',
              alignContent: 'stretch',
              justifyContent: 'space-evenly',
              height: '100%'
            }}>
              {(this.state.focused && this.state.focusedEvent) ?

                  <EventCard event={this.state.focusedEvent} key={0} redirect={false} onCardClick={onCardClick}
                             params={this.state.params}
                             focused={true}
                             single={true}
                             style={{
                               justifyContent: 'center',
                               flexGrow: 1,
                               flexShrink: 3,
                               //width: '30%'/*, maxHeight:'350px'/*, width: '100px', maxHeight:'200px', marginBottom: '20px', marginRight: '20px', marginLeft: '0px*/
                             }}/>

                  :
                  (this.state.events.map((elem, index) =>
                      <EventCard event={elem} key={index} redirect={!this.props.details}
                                 params={this.state.params}
                                 onCardClick={onCardClick}
                                 focused={false}
                                 style={{
                                   flexGrow: 1,
                                   flexShrink: 3,
                                   width: '30%'/*, maxHeight:'350px'/*, width: '100px', maxHeight:'200px', marginBottom: '20px', marginRight: '20px', marginLeft: '0px*/
                                 }}/>
                  ))}
            </div>
            {this.state.focused ? null : <div style={{display: "flex"}}>
              <Pagination style={{justifyContent: 'center', margin: "0 auto"}}
                          count={Math.round(this.state.maxEvents / this.state.maxPerPage)} page={this.state.page}
                          onChange={handleChange} showFirstButton showLastButton/>
            </div>}
            <p style={{textAlign: 'end', color: this.state.params.color, opacity: '0.6', fontSize: '0.9em', marginRight: '1em'}}>Powered by <a
                href={config.WEBSITE_URL} target='_blank' rel="noopener noreferrer">Timenjoy ©</a></p>
            {myErrorMessage}
          </>
      )
    } else if (this.state.events && this.state.events.length === 0){
      return (
          <>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: 'wrap',
              alignContent: 'stretch',
              justifyContent: 'space-evenly',
              height: '100%'
            }}>
                <Typography gutterBottom variant="overline" component="p" style={{textOverflow: 'ellipsis', height: '64px'}}>
                    <p>{'Désolé,  Aucun événement en ce moment pour cette catégorie'}</p>
                </Typography>
            </div>
          </>
      )
    }

    return (
        <Loader
          style={{marginTop: '35vh'}}
          type="RevolvingDot"
          color={this.state.params.color ? this.state.params.color : 'grey'}
          height={100}
          width={100}
        />
    );
  }
}
const mapStateToProps = (state) => ({
  activeApiToken: state.apiToken.activeApiToken,
  // errorMessage: state.apiToken.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
  errorMessagesActions: bindActionCreators(errorMessagesActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageView);

// export default connect(
//     ({ pageView }) => ({ ...pageView }),
//     dispatch => bindActionCreators({ ...pageViewActions }, dispatch)
//   )( pageView );

import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { apiToken } from "./apiToken/reducers";
import { errorMessage } from "./errorMessage/reducers";

export const rootReducer = {
  apiToken,
  errorMessage,
};
// const devTool =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
//       window.__REDUX_DEVTOOLS_EXTENSION__()
//     : compose;

const devTool =
  process.env.NODE_ENV === "development"
    ? (window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
    : compose;

// console.log('devTool', devTool)
const store = createStore(
  combineReducers(rootReducer),
  compose(
    applyMiddleware(ReduxThunk),
    devTool
  )
);

export default store;

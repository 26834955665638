import React, {Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  //Switch,
  //Route,
  useLocation,
  // withRouter
} from "react-router-dom";
//import * as CryptoJS from 'crypto-js';
// import ErrorDialog from './components/errorDialog';
//import apiTokenService from './services/apiToken';
import * as errorMessagesActions from "./store/errorMessage/actions";
import * as apiTokenActions from "./store/apiToken/actions";
// import logo from './logo.svg';
import './App.scss'
import Frame1 from './containers/frame1';
// import Embedded from './containers/embedded/Embedded';
import PageView from './containers/pageView';

import TagManager from 'react-gtm-module'

class App extends Component {

  constructor(props) {
      super(props);
      this.state = {
        activeApiToken: props.activeApiToken,
        errorMessage: props.errorMessage
      };
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID
      }
      TagManager.initialize(tagManagerArgs)
  }

  // componentDidUpdate() {
  //   if (!this.state.activeApiToken) {
  //     // dispatch(getApiToken());
  //     apiTokenService.getApiToken()
  //     .then(awns => {
  //       this.props.apiTokenActions.setActiveApiToken(awns);
  //       // this.setState({activeApiToken: awns, errorMessage: "Une Erreur"});
  //       this.setState({activeApiToken: awns});
  //     }).catch(err => {
  //       this.props.errorMessagesActions.setActiveErrorMessage(err.message);
  //       this.setState({errorMessage: err.message});
  //       // console.log(err)
  //     });
  //   }
  //   console.log('state.activeApiToken', this.state.activeApiToken);
  // }

  // componentDidMount() {
  //   if (!this.state.activeApiToken) {
  //     apiTokenService.getApiToken(this.state.app_secret)
  //     .then(awns => {
  //       this.props.apiTokenActions.setActiveApiToken(awns);
  //       // this.setState({activeApiToken: awns, errorMessage: "Une Erreur"});
  //       this.setState({activeApiToken: awns});
  //     }).catch(err => {
  //       this.props.errorMessagesActions.setActiveErrorMessage(err.message);
  //       this.setState({errorMessage: err.message});
  //       // console.log(err)
  //     });
  //   }
  //   // console.log('state.activeApiToken', this.state.activeApiToken);
  // }

  render() {
    // A custom hook that builds on useLocation to parse
    // the query string for you.
    const useQuery = () => {
      return new URLSearchParams(useLocation().search);
    };

    /*let myFrame1 = null;
    if (this.state.activeApiToken) {
      myFrame1 = (
        <Frame1 style={this.props.style}/>
      );
    }*/

    let myErrorMessage = null;
    if (this.state.errorMessage) {
      // <ErrorDialog handleClose = {handleClose} errorMessage={this.state.errorMessage} />
      myErrorMessage = (
        this.state.errorMessage
      );
    }


    const QueryParamsDemo = () => {
      let url = useLocation().pathname;
      let query = useQuery();
      let app_secret;
      let city;
      let dist;
      let topevents;
      let categories;
      let tags;
      let max;
      let white;

      let params = {
        backgroundColor: query.get("backgroundColor"),
        color: query.get("color"),
        buttonBackgroundColor: query.get("buttonBackgroundColor"),
        buttonColor: query.get("buttonColor"),
        font: query.get("font"),
        googleFont: query.get("googleFont"),
      }

      // parsing is needed to use the input in the newLink with '+' instead of space.
      let parsedFont = params.googleFont ? params.googleFont.replace(/\s/g, '+') : null;
      const fontLink = parsedFont
                      ? <link href={'https://fonts.googleapis.com/css2?family=' + parsedFont + '&display=swap'} rel='stylesheet' />
                      : null;

      if (this.props.options.app_secret) {
        // app_secret = decodeURIComponent(this.props.options.app_secret);
        app_secret = this.props.options.app_secret;
        city = this.props.options.city;
        dist = this.props.options.dist;
        topevents = this.props.options.topevents;
        categories = this.props.options.categories;
        tags = this.props.options.tags;
        max = (!this.props.options.max) ? 12 :  parseInt(this.props.options.max, 10);
        white = (this.props.options.white) ? true : false;
        //redirect = (this.props.?options.?max ) ? false : true;
      } else {
        app_secret = query.get("app_secret");
        city = query.get("city");
        dist = query.get("dist");
        topevents = query.get("topevents");
        categories = query.get("categories");
        tags = query.get("tags");
        max = (!query.get("max")) ? 12 :  parseInt(query.get("max"), 10);
        white = (query.get("white")) ? true : false;
      }
      if (isNaN(max))
        max = 12;
      else if (max > 30)
        max = 30;
      //TODO const categories = (query.get("categories")) ? query.get("categories").split('+') : 12;
      //const details = (query.get("details")) ? true : false; // todo
      // console.log("app_secret", app_secret);

      if (url === "/carousel" || this.props.options.type==="tj-carousel") {
        // console.log("PROPS", this.props);
        return (
          <div className="App">
            {fontLink}
            <Frame1 style={this.props.style} app_secret={app_secret} params={params}
                    city={city} dist={dist} topevents={topevents} categories={categories} tags={tags} max={max} details={white}
            />
            { /* myFrame1 */ }
            { myErrorMessage }
            { /* <Child appId={query.get("appId")} /> */ }
          </div>
        );
      // } else if (url === "/embedded" || this.props.options.type==="embedded")
      // {
      //   return (
      //         <Embedded app_secret={app_secret} city={city} dist={dist} topevents={topevents} categories={categories} tags={tags} />
      //   );
      } else if (url === "/page-view" || this.props.options.type==="page-view") {
        return (
            <div className="App">
              {fontLink}
              <PageView app_secret={app_secret} params={params} city={city}
                        dist={dist} topevents={topevents} categories={categories} details={white} tags={tags}  />
            </div>
        );
      }
      else {
        myErrorMessage = "404 Bad URL";
        return (
          <div className="App">
            { myErrorMessage }
          </div>
        );
      }
    }

    return (
      <Router>
        <QueryParamsDemo />
        { /*
        <Switch>
          <Route path="/carousel?appId=xxxxxxxxxxx">
            <div className="App">
              {appId ? (
                <h1>appID is {appId}</h1>
              ) : (
                <h1> NO appId </h1>
              )}
              { myFrame1 }
              { myErrorMessage }
            </div>
          </Route>
          <Route path="/">
            <div>
              <h1> HOME </h1>
            </div>
          </Route>
        </Switch>
        */ }
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  activeApiToken: state.apiToken.activeApiToken,
  errorMessage: state.apiToken.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
  errorMessagesActions: bindActionCreators(errorMessagesActions, dispatch),
})
// export default connect(
//     ({ frame1 }) => ({ ...frame1 }),
//     dispatch => bindActionCreators({ ...frame1Actions }, dispatch)
//   )( frame1 );

export default connect(mapStateToProps, mapDispatchToProps)(App);

import ApiToken from "../../services/apiToken";
import { mutation } from "./mutations";

const handleError = err => {
  console.error("Error in apiToken action : ", err);
};

// Actions
// export const getApiToken = () => async dispatch => {
//   try {
//     dispatch(mutation.isFetchingData(true))
//     let result = await ApiToken.getApiToken();
//     if (result) {
//       // dispatch(mutation.setApiTokenList(result ? result : []));
//       dispatch(mutation.setActiveApiToken(result));
//       dispatch(mutation.isFetchingData(false));
//       // return result;
//     }
//   } catch (err) {
//     handleError(err);
//   }
// };
export const getApiToken = () => dispatch => {

    dispatch(mutation.isFetchingData(true))
    return ApiToken.getApiToken()
    .then(result => {
      dispatch(mutation.setActiveApiToken(result));
      dispatch(mutation.isFetchingData(false));
    });

};

// export const getApiTokenListRequest = () => async dispatch => {
//   try {
//     dispatch(mutation.isFetchingData(true))
//     let result = await ApiToken.getApiTokenList();
//     if (result) {
//       dispatch(mutation.setApiTokenList(result ? result : []));
//       dispatch(mutation.isFetchingData(false))
//       return result;
//     }
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const createApiTokenRequest = (payload) => async dispatch => {
//   try {
//     let result = await ApiToken.createApiToken(payload);
//     if (result) {
//       dispatch(mutation.setApiTokenListData(result));
//       return result;
//     }
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const getApiTokenByIdRequest = (id) => async dispatch => {
//   try {
//     let result = await ApiToken.getApiTokenById(id);
//     dispatch(mutation.setActiveApiToken(result));
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const updateApiTokenByIdRequest = (id,payload) => async dispatch => {
//   try {
//     let result = await ApiToken.updateApiTokenById(id,payload);
//     if (result) {
//       dispatch(mutation.setUpdateApiTokenListData(result));
//       return result;
//     }
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const deleteApiTokenByIdRequest = (id) => async dispatch => {
//   try {
//     dispatch(mutation.removeApiTokenFromList(id));
//     let result = await ApiToken.deleteApiTokenById(id);
//   } catch (err) {
//     handleError(err);
//   }
// };

export const setActiveApiToken = data => async dispatch => {
  try {
    dispatch(mutation.setActiveApiToken(data));
  } catch (err) {
    handleError(err);
  }
};

import { initialState } from "./states";

export const apiToken = (state = initialState, action) => {
  switch (action.type) {
    // case "SET_APITOKEN_LIST": {
    //   return Object.assign({}, state, {
    //     apiTokenList: action.data
    //   });
    // }
    // case "GET_APITOKEN": {
    //   return Object.assign({}, state, {
    //     getApiToken: action.data
    //   });
    // }
    case "SET_ACTIVE_APITOKEN": {
      return Object.assign({}, state, {
        activeApiToken: action.data
      });
    }
    // case "SET_APITOKEN_LIST_DATA": {
    //   const data = [...state.apiTokenList];
    //   data.unshift(action.data);
    //   return Object.assign({}, state, {
    //     apiTokenList: data
    //   });
    // }
    // case "REMOVE_APITOKEN_FROM_LIST": {
    //   const data = state.apiTokenList.filter(data => data.id !== action.data);
    //   return Object.assign({}, state, {
    //     apiTokenList: data
    //   });
    // }
    // case "SET_UPDATE_APITOKEN_LIST_DATA": {
    //   const data = state.apiTokenList.map(data => {
    //     if (data.id === action.data.id) {
    //       return (data = action.data);
    //     } else {
    //       return data;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     apiTokenList: data
    //   });
    // }
    case "IS_FETCHING_DATA": {
      return Object.assign({}, state, {
        isFetching: action.data
      });
    }
    default:
      return state;
  }
};

import React, { useState } from "react";
import DateService from "../../services/Date";
import EventService from "../../services/event";
import PropTypes from 'prop-types';

import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";

import PinDropIcon from '@material-ui/icons/PinDrop';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
// import Visibility from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';

import {makeStyles} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import config from '../../services/config/config';
import { Api } from "../../services/config/request";
import standardSlugify from "standard-slugify";
import TagManager from 'react-gtm-module'

const useStyles = makeStyles({
    root: params => ({
        minHeight: '275px',
        margin: '20px',
        justifyContent: 'center',
        backgroundColor: params.backgroundColor,
    }),
    sm_title: params => ({
        fontWeight: 'bold',
        textOverflow: 'hidden',
        overflow: 'hidden',
        height: '40px',
        fontSize: '0.9em',
        fontFamily: params.googleFont ? params.googleFont : params.font,
        color: params.color,
    }),
    m_title: params => ({
        fontWeight: 'bold',
        textOverflow: 'hidden',
        overflow: 'hidden',
        height: '40px',
        fontSize: '1.2em',
        fontFamily: params.googleFont ? params.googleFont : params.font,
        color: params.color,
    }),
    info: params => ({
        fontFamily: params.googleFont ? params.googleFont : params.font,
        textAlign: 'start',
        marginLeft: '2%',
        lineHeight: '1.5rem',
        color: params.color,
        opacity: '0.8'
    }),
    icon: {
        fontSize: '0.9rem',
    },
    card_actions: params => ({
      flexDirection: 'row-reverse' // 'column'
    }),
    button: params => ({
      fontFamily: params.googleFont ? params.googleFont : params.font,
      color: params.buttonColor ? params.buttonColor : "white",
      backgroundColor: params.buttonBackgroundColor ? params.buttonBackgroundColor : "#053560",
      fontWeight: "bold",
      fontSize: "0.6rem",
      // border: '1px solid ' + params.color,
      "&:hover, &:focus": {
        color: params.buttonColor ? params.buttonColor : "white",
        backgroundColor: params.buttonBackgroundColor ? params.buttonBackgroundColor : "#053560",
        filter: "brightness(150%)",
        // webkitFilter: "brightness(130%)",
        // -moz-filter: brightness(130%);
        // -o-filter: brightness(130%);
        // -ms-filter: brightness(130%);
        // -webkit-transition: all 0.50s;
        transition: "all 0.50s",
        // opacity: '0.8'
      }
      // opacity: '0.6'
    })
});


// TO DO:
// Call props from index.js and use it as params for the makeStyles.
// Add option, if input is a GoogleFont, add the <link> in the right place.

const EventCard = props => {
    const [FocusedState, setFocusedState] = useState(
        {
            focused: (props.focused !== undefined && props.focused != null) ? props.focused : false,
            event: null
        }
        );

        const params = props.params;
        const classes = useStyles(params);
    const mediumScreen = useMediaQuery('(min-width:696px)');
    //const bigScreen = useMediaQuery('(min-width:1300px)');

    const stripScripts = (s) => {
        var div = document.createElement('div');
        div.innerHTML = s;
        var scripts = div.getElementsByTagName('script');
        var i = scripts.length;
        while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
        }
        return div.innerHTML;
    };


    const onClickCard = (event = null) => {
        if (props.redirect === true) {
          // console.log(props.event);
          let referencingString = EventService.computeReferencingString(props.event);
          EventService.computeUTM(props.event)
           .then(utmString => {
             const url = config.WEBSITE_URL + '/event' + referencingString + '/' + props.event.id + utmString;
             // let win = window.open(config.WEBSITE_URL + '/evenement/' + props.event.id + referencingString, '_blank');
             let win = window.open(url, '_blank');
             win.focus();
           })
            // let win = window.open(config.WEBSITE_URL + '/evenement/' + props.event.id + EventService.computeReferencingString(props.event), '_blank');
            // win.focus();
        } else {
            if (props.onCardClick) {
                props.onCardClick(event);
            }
            if (props.carousel === true) {
              let url = window.location.pathname;
              Api.getRequest('/auth/validate_token')
              .then(res => {
                let tagManagerArgs = {
                  dataLayer: {
                    'event': 'generic',
                    'app_id': res.user.appId,
                    'company_name': standardSlugify(res.user.companyname),
                    'environment': process.env.REACT_APP_ENVIRONMENT,
                    'eventName': standardSlugify(props.event.title),
                    'pageType': 'evenement' //url.split('/')[1]
                  },
                }
                TagManager.dataLayer(tagManagerArgs)
                tagManagerArgs = {
                  dataLayer: {
                    'event': 'pageview',
                    'page Path': config.IFRAME_URL + url
                  }
                }
                TagManager.dataLayer(tagManagerArgs)

                event.iframe_url = config.IFRAME_URL;
                window["sendArgs"](event);
              })
            } else {
                if (!props.single) {
                  let url = window.location.pathname;
                  Api.getRequest('/auth/validate_token')
                  .then(res => {
                    let tagManagerArgs = {
                      dataLayer: {
                        'event': 'generic',
                        'app_id': res.user.appId,
                        'company_name': standardSlugify(res.user.companyname),
                        'environment': process.env.REACT_APP_ENVIRONMENT,
                        'eventName': standardSlugify(props.event.title),
                        'pageType': 'evenement' //url.split('/')[1]
                      },
                    }
                    TagManager.dataLayer(tagManagerArgs)
                    tagManagerArgs = {
                      dataLayer: {
                        'event': 'pageview',
                        'page Path': config.IFRAME_URL + url
                      }
                    }
                    TagManager.dataLayer(tagManagerArgs)
                  });
                }
                setFocusedState({focused: (props.single) ? false : !FocusedState.focused});
            }
        }
    };

    var SingleDetailContent = (
        <CardActionArea style={{justifyContent: 'center'}} onClick={() => onClickCard(props.event)}  >
        {/*props.event.image ? <img style={{width: '100%', height: '30%'}} src={props.event.image} alt="Red dot" onClick={() => onClickCard(props.event)} /> :
            props.event.pictures && props.event.pictures[0] ?
                <img style={{width: '100%'}} src={props.event.pictures[0].file} alt="Red dot" onClick={() => onClickCard(props.event)} /> : <img style={{width: '100%', height: '30%'}} src={props.event.picture_url} alt="Red dot" onClick={() => onClickCard(props.event)}/>*/}

            <div >
                <Typography gutterBottom variant="h5" component="h2" style={{textOverflow: 'ellipsis', height: '64px'}}>
                    <p>{props.event.title.slice(0,32)}{props.event.title.length > 32 ? '...': ''}</p>
                </Typography>
                <img style={{width: '30%', height: '10%'}} src={props.event.picture_url} alt="Red dot" onClick={() => onClickCard(props.event)}/>
            </div>

            { (props.event.description) ? <div dangerouslySetInnerHTML={{ __html: stripScripts(props.event.description)}} /> : <p>Description not found</p>  }
        <CardContent onClick={() => onClickCard(props.event)}  >

            <iframe
            title={'TjMapp'}
            src={"https://www.stay22.com/embed/gm?aid=timenjoy&showgmapsicon=true&lat=" + props.event.coordinate.lat + "&lng=" + props.event.coordinate.lng}
            id="stay22-widget"
            width="100%" height="460" frameborder="0">
            </iframe>
                <Typography variant="body2" color="textSecondary" component="p" style={{justifyContent: 'flex-start'}}>

                    <EventNoteIcon/> {DateService.date(props.event.nextDate,props.event.nextDatefin)} <br/>
                    <PinDropIcon/> {props.event.place} <br/>
                    {props.event.categories && props.event.categories[0] ? <><LocalOfferIcon/> {props.event.categories[0].name} </>: null}
                </Typography>
            </CardContent>
            {<CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
                <IconButton
                    onClick={() => {}}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>}
        </CardActionArea>
    );

    var GroupContent = (
        <CardActionArea onClick={() => onClickCard(props.event)}>
            {props.event.picture_url ? <img style={{width: '100%'}} src={props.event.picture_url} alt="Event Poster" /> : null}

            <CardContent>
                <Typography className={(mediumScreen) ? classes.m_title : classes.sm_title} gutterBottom variant="h5" component="h2" >
                    {props.event.title.slice(0,25)}{props.event.title.length > 25 ? '...': ''}
                </Typography>
                <Typography className={classes.info} variant="body2" color="textSecondary" component="p">
                    <EventNoteIcon className={classes.icon} /> {DateService.date(props.event.nextDate, props.event.nextDatefin)} <br/>
                    <PinDropIcon className={classes.icon} />{props.event.place.slice(0,20)}{props.event.place.length > 20 ? '...': ''} <br/>
                    {props.event.categories && props.event.categories[0] ? <><LocalOfferIcon className={classes.icon} /> {props.event.categories[0].name}</> : <div style={{color: '#000000FF'}}>{props.event.place}</div>}
                </Typography>
            </CardContent>
            <CardActions className={classes.card_actions}>
              <Button className={classes.button} variant="outlined" size="small" >
                Découvrir
              </Button>
            </CardActions>
        </CardActionArea>
    );

    if (!FocusedState.focused && !(props.single !== undefined && props.single === true))
    {
        return (
            <Card className={classes.root} style={props.style}>
                {GroupContent}
            </Card>
        )
    } else {
      return (
          <Card className={classes.root} style={props.style} /*onClick={props.onclickevent ? () => props.onclickevent(props.event) : null}*/>
              {SingleDetailContent}
          </Card>
      );
    }
};

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
    redirect: PropTypes.bool,
    style: PropTypes.object,
    single: PropTypes.bool,
    focused: PropTypes.bool,
};

export default EventCard;

//import React from 'react';

const test = (process.env.REACT_APP_ENVIRONMENT !== 'production');
//TODO interface ?
const prod = {
    debug: test,
    API_URL: 'https://apisails.timenjoy.fr',
    WEBSITE_URL: 'https://timenjoy.fr',
    IFRAME_URL: 'https://iframe.timenjoy.fr'
};

const devt = {
    debug: test,
    API_URL: 'https://apisails-dev.timenjoy.fr',
    WEBSITE_URL: 'https://timenjoy-dev.timenjoy.fr',
    IFRAME_URL: 'https://iframe-dev.timenjoy.fr'
    // IFRAME_URL: 'http://localhost:3000'
};

const local = {
    debug: test,
    API_URL: 'localhost:1338',
    WEBSITE_URL: 'http://localhost:4300',
    IFRAME_URL: 'http://localhost:3000'
};

const config = (process.env.REACT_APP_ENVIRONMENT === 'development') ? devt : ((process.env.REACT_APP_ENVIRONMENT === 'local') ? local : prod);
export default config;

import React, {Component} from 'react';
// import Carousel from 'react-material-ui-carousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EventCard from '../../components/eventCard';
import Typography from "@material-ui/core/Typography";
import Loader from 'react-loader-spinner';

// import authService from '../../services/auth';
import apiTokenService from '../../services/apiToken';
import categoryService from '../../services/category';
import eventService from '../../services/event';
import tagService from '../../services/tag';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
// import store from '../../store';
import * as errorMessagesActions from "../../store/errorMessage/actions";
import * as apiTokenActions from "../../store/apiToken/actions";

import ErrorDialog from '../../components/errorDialog';
import config from '../../services/config/config';

import { Api } from "../../services/config/request";
import standardSlugify from "standard-slugify";
import TagManager from 'react-gtm-module'

import './frame1.scss'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as frame1Actions from "../../store/frame1/actions";

class Frame1 extends Component {
  constructor(props) {
      super(props);
      this.state = {
        events: null,
        app_secret: this.props.app_secret,
        city: this.props.city,
        dist: this.props.dist,
        topevents: this.props.topevents,
        input_categories: this.props.categories,
        input_tags: this.props.tags,
        categories: [],
        params: this.props.params,
        defilement: true,//TODO device type mobile ? false
      };
      // getAuthToken();
  }

  componentDidMount() {
    if (!this.state.activeApiToken) {
      apiTokenService.getApiToken(this.state.app_secret)
      .then(awns => {
        this.props.apiTokenActions.setActiveApiToken(awns);
        // this.setState({activeApiToken: awns, errorMessage: "Une Erreur"});
        this.setState({activeApiToken: awns});
        return categoryService.getCategoryList()
      })
      .then(response => {
        const TJcategories =response ? response.rows : [];
        // this.setState({
        //   categories: response ? response.rows : []
        // });
        if (this.state.input_categories) {
          const categories = [];
          JSON.parse(this.state.input_categories).forEach(input_cat => {
            const catId = TJcategories
            .filter(x => input_cat.toUpperCase() === x.name)
            .map(x => x.id);
            if (catId) {
              categories.push(catId[0]);
            }
            // console.log("catId", catId)
          })
          this.setState({categories: categories});
        }

        if (this.state.input_tags) {
          const myImputTags = JSON.parse(this.state.input_tags)
          return tagService.searchTag(myImputTags.join(' '));
        } else {
          return null;
        }
      })
      .then(response => {
        const TJtags = response ? response.rows : [];
        const tags = TJtags.map(x => x.id);
        return eventService.getEventList(this.state.city?this.state.city:null,
          this.state.dist?this.state.dist:null,
          this.state.topevents?this.state.topevents:null,
          this.state.categories.length > 0 ? this.state.categories:null,
          tags.length > 0 ? tags:null
        );
      })
      .then(events => {
        // const events = response.rows.map (x => {
        //   x.image = API_URL + '/TJapi/event/' + x.id + '/getImage';
        //   return x;
        // })
        // console.log(events.rows);
        this.setState({
          events: events.rows
        });
      })
      .then(() => {
        let url = window.location.pathname;
        Api.getRequest('/auth/validate_token')
        .then(res => {
          let tagManagerArgs = {
            dataLayer: {
              'event': 'generic',
              'app_id': res.user.appId,
              'company_name': standardSlugify(res.user.companyname),
              'environment': process.env.REACT_APP_ENVIRONMENT,
              'eventName': '',
              'pageType': url.split('/')[1]
            },
          }
          TagManager.dataLayer(tagManagerArgs)
          tagManagerArgs = {
            dataLayer: {
              'event': 'pageview',
              'page Path': config.IFRAME_URL + url
            }
          }
          TagManager.dataLayer(tagManagerArgs)
        });
      })
      .catch(err => {
        this.setState({
          events: []
        });
        this.props.errorMessagesActions.setActiveErrorMessage(err.message);
        this.setState({errorMessage: err.message});
        // console.log(err)
      });
    } else {
      categoryService.getCategoryList()
      .then(response => {
        const TJcategories =response ? response.rows : [];
        // this.setState({
        //   categories: response ? response.rows : []
        // });
        if (this.state.input_categories) {
          const categories = [];
          JSON.parse(this.state.input_categories).forEach(input_cat => {
            const catId = TJcategories
            .filter(x => input_cat.toUpperCase() === x.name)
            .map(x => x.id);
            if (catId) {
              categories.push(catId[0]);
            }
            // console.log("catId", catId)
          })
          this.setState({categories: categories});
        }

        if (this.state.input_tags) {
          const myImputTags = JSON.parse(this.state.input_tags)
          return tagService.searchTag(myImputTags.join(' '));
        } else {
          return null;
        }
      })
      .then(response => {
        const TJtags_arrays = response ? response : [];
        let TJtags = [];
        TJtags_arrays.forEach(aTagArray => {
          TJtags = TJtags.concat(aTagArray.rows)
        })
        const tags = TJtags.map(x => x.id);
        return eventService.getEventList(this.state.city?this.state.city:null,
          this.state.dist?this.state.dist:null,
          this.state.topevents?this.state.topevents:null,
          this.state.categories.length > 0 ? this.state.categories:null,
          tags.length > 0 ? tags:null
        );
      })
      .then(events => {
        // const events = response.rows.map (x => {
        //   x.image = API_URL + '/TJapi/event/' + x.id + '/getImage';
        //   return x;
        // })
        // console.log(events.rows);
        this.setState({
          events: events.rows
        });
      })
      .catch(err => {
        this.props.errorMessagesActions.setActiveErrorMessage(err.message);
        this.setState({'errorMessage': err.message});
      })
    }





    // const getCategories = async () => {
    //   const response = await categoryService.getCategoryList();
    //   this.setState({
    //     categories: response ? response.rows : []
    //   })
    // };
    // getCategories();

    // const getAuthToken = async () => {
    //   const response = await authService.getAuthToken();
    //   this.setState({
    //     appToken: response.token
    //   })
    // };
    // getAuthToken();
    // const fetchUserEmail = async () => {
    //   const response = await fetch("/emails");
    //   const { email } = await response.json();
    //   this.setState({
    //     email
    //   });
    // };
    // fetchUserEmail();
  }

  onClickEvent = () => {
      this.setState({defilement: !this.state.defilement});
  };


  render() {
    let responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide:  5,//Math.floor(this.state.events.length / 5)
      },
      midDesktop : {
        breakpoint: { max: 1195, min: 1024 },
        items: 4,
        slidesToSlide:  4
      },
      tablet: {
        breakpoint: { max: 1024, min: 695 },
        items: 3,
        slidesToSlide: 3
      },
      midTablet: {
        breakpoint: { max: 695, min: 464 },
        items: 2,
        slidesToSlide:  2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide:  1
      }
    };

    const handleClose = () => {
      this.setState({'errorMessage': null});
    }

    let myErrorMessage = null;
    // console.log(store.getState());
    if (this.state.errorMessage) {
      myErrorMessage = (
        <ErrorDialog handleClose={handleClose} errorMessage={this.state.errorMessage} />
      )
    }

    /*let change = false;
    if ((this.state.events.length < 5 && this.state.events.length > 0) && change === false) {
      for (const [key, value] of Object.entries(responsive)) {
        for (const [key1, value1] of Object.entries(value)) {
          console.log(key + ' ' + value + ' ' + key1 + ' ' + value1 + ' ');
          if (key1 === 'items')
            console.log(' adff ' + this.state.events.length)
            value[key1] = this.state.events.length
        }
      }
      change = true;
    }*/

    if (this.state.events && this.state.events.length === 0){
      return (
        <Typography gutterBottom variant="overline" component="p" style={{textOverflow: 'ellipsis', height: '64px'}}>
        <p>{'Désolé,  Aucun événement en ce moment pour cette catégorie'}</p>
        </Typography>
      )
    } else if (this.state.events && this.state.events.length > 0) {
      return (
        <div >
        <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        autoPlay={this.state.defilement}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['superLargeDesktop', 'desktop', 'midDesktop', "tablet", "midTablet", "mobile"]}
        deviceType={null}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        >
        {
          this.state.events.map( (item, index) => <EventCard single={false} carousel={true} event={item} onCardClick={this.onClickEvent} key={item.id} redirect={!this.props.details} params={this.state.params} style={{flexGrow: 1, maxHeight: this.props.style.height, maxWidth: this.props.style.width}}/> )
        }

        </Carousel>
        <p style={{textAlign: 'end', color: this.state.params.color, opacity:"0.6", fontSize: '0.9em', marginRight: '1em'}}>Powered by <a href={config.WEBSITE_URL} target='_blank' rel="noopener noreferrer">Timenjoy ©</a></p>
        { myErrorMessage }
        </div>
      )
    }
    return (
        <Loader
          style={{marginTop: '35vh'}}
          type="RevolvingDot"
          color={this.state.params.color ? this.state.params.color : 'grey'}
          height={100}
          width={100}
        />
    );
  }


}

const mapStateToProps = (state) => ({
  activeApiToken: state.apiToken.activeApiToken,
  // errorMessage: state.apiToken.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
  errorMessagesActions: bindActionCreators(errorMessagesActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Frame1)
// const EventCard = props => {
//   return (
//     <Paper>
//     <h2>{props.event.name}</h2>
//     <p>{props.event.description}</p>
//
//     <Button className="CheckButton" color="primary">
//     Check it out!
//     </Button>
//     </Paper>
//   )
// }


// export default connect(
//     ({ frame1 }) => ({ ...frame1 }),
//     dispatch => bindActionCreators({ ...frame1Actions }, dispatch)
//   )( frame1 );

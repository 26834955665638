import { Api } from "./config/request";

export const getCategoryList =() => {
  // return Api.getListRequest("/category");
  const params = {
    page:1,
    perPage:30
  }
  return Api.getRequest("/TJapi/category", params);
};
// export const getCategoryById =id => {
//   return Api.getRequest(`/TJapi/category/${id}`);
// };
// export const createCategory = payload => {
//     return Api.postRequest("",payload);
// };
//   export const updateCategoryById = (id,payload)  => {
//     return Api.putRequest(`${id}`,payload);
// };
//   export const deleteCategoryById = id => {
//     return Api.deleteRequest(`${id}`);
// };

const Category = {
    getCategoryList ,
    // getCategoryById,
    // createCategory,
    // updateCategoryById,
    // deleteCategoryById
};

export default Category

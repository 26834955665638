import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import './errorDialog.scss'

const ErrorDialog = (props) => {
  return  <Dialog onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={true} color="secondary">
            <DialogTitle id="simple-dialog-title" color="secondary">{props.errorMessage}</DialogTitle>
            <DialogActions>
              <Button onClick={props.handleClose} color="secondary">
                OK
              </Button>
            </DialogActions>
          </Dialog>;
}

export default ErrorDialog;

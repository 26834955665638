import axios from "axios";
// import store from "@/store";
// import authService from '../../services/auth';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { API_URL } from "./apiUrl";
import store from '../../store';

const getAuthToken = (app_secret) => {
  let params = {
      appId:  null, // process.env.REACT_APP_API_KEY,
      password: null, // process.env.REACT_APP_API_PASS,
      expiredDate: moment().add(1, 'minutes').format(),
  };
    var bytes = CryptoJS.AES.decrypt(app_secret, process.env.REACT_APP_ENCRYPT_SECRET);
    // console.log("bytes:", bytes.toString(CryptoJS.enc.Utf8));
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    params.appId = decryptedData.appId;
    params.password = decryptedData.appPassword;

    const url = "/auth/login";
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(params), process.env.REACT_APP_ENCRYPT_SECRET).toString();
    const data = {
      appId: encodeURIComponent(encrypted),
      password: 'null'
    }

    return axios.post(API_URL + url, data)
    .then(response => {
      return ("Bearer " + (response.data ? response.data.token : response.token));
    })
    .catch( err => {
      throw err;
    })
}

const _authorizationHeaders = () => {
  const state = store.getState();

  return ({
    Authorization: (state.apiToken && state.apiToken.activeApiToken) ? state.apiToken.activeApiToken : "",
    "Content-Type": "application/json"
  })


};

const handleError = err => {
  // const dispatch =store.dispatch();
  // const actions = bindActionCreators(errorMessageActions, dispatch);
  // actions.setActiveErrorMessage(`Api call error in services -> request.js : `, err);
  console.error(`Api call error in services -> request.js : `, err);
};

// export const getRequest = async (url, params = null, headers = _authorizationHeaders()) => {
//   try {
//     const res = await axios.get(API_URL + url, {
//       headers: Object.assign({}, headers),
//       params: Object.assign({}, params),
//     });
//     return res.data.result ? res.data.result : res.data;
//   } catch (err) {
//     handleError(err);
//   }
// };
export const getRequest = (url, params = null, headers = _authorizationHeaders()) => {
  return  axios.get(API_URL + url, {
      headers: Object.assign({}, headers),
      params: Object.assign({}, params),
    })
    .then(res => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch(err => {
      throw err;
    })
};
// export const getListRequest = async (
//   url,
//   params = null,
//   headers = _authorizationHeaders()
// ) => {
//   try {
//     const res = await axios.get(API_URL + url, {
//       headers: Object.assign({}, headers)
//     });
//     return res.data.result ? res.data.result : res.data;
//   } catch (err) {
//     handleError(err);
//   }
// };

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const putRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const patchRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

// export const deleteRequest = async (url, headers = _authorizationHeaders()) => {
//   try {
//     const res = await axios({
//       url: API_URL + url,
//       method: "DELETE",
//       headers: Object.assign({}, headers)
//     });
//     return res.data.result ? res.data.result : res.data;
//   } catch (err) {
//     handleError(err);
//   }
// };

export const Api = {
  getAuthToken,
  // deleteRequest,
  // getListRequest,
  getRequest,
  postRequest,
  putRequest,
  patchRequest
};

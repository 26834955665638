import { Api } from "./config/request";

export const getApiToken = (app_secret) => {
  return Api.getAuthToken(app_secret);
};

const ApiToken = {
    getApiToken
};

export default ApiToken;

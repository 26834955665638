// const setErrorMessageList = data => ({
//     type: "SET_ERRORMESSAGE_LIST",
//     data
//   });

  const setActiveErrorMessage = data => ({
    type: "SET_ACTIVE_ERRORMESSAGE",
    data
  });
  const clearErrorMessage = data => ({
    type: "CLEAR_ERRORMESSAGE",
    data
  });
  // const setErrorMessageListData = data => ({
  //   type: "SET_ERRORMESSAGE_LIST_DATA",
  //   data
  // });
  //
  // const removeErrorMessageFromList = data => ({
  //   type: "REMOVE_ERRORMESSAGE_FROM_LIST",
  //   data
  // });
  //
  // const setUpdateErrorMessageListData = data => ({
  //   type: "SET_UPDATE_ERRORMESSAGE_LIST_DATA",
  //   data
  // });
  //
  // const isFetchingData = data => ({
  //   type: "IS_FETCHING_DATA",
  //   data
  // });


  export const mutation = {
    // setErrorMessageList,
    setActiveErrorMessage,
    clearErrorMessage,
    // setErrorMessageListData,
    // removeErrorMessageFromList,
    // setUpdateErrorMessageListData,
    // isFetchingData
  };

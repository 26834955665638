// const setApiTokenList = data => ({
//   type: "SET_APITOKEN_LIST",
//   data
// });

const setActiveApiToken = data => ({
  type: "SET_ACTIVE_APITOKEN",
  data
});

// const setApiTokenListData = data => ({
//   type: "SET_APITOKEN_LIST_DATA",
//   data
// });
//
// const removeApiTokenFromList = data => ({
//   type: "REMOVE_APITOKEN_FROM_LIST",
//   data
// });
//
// const setUpdateApiTokenListData = data => ({
//   type: "SET_UPDATE_APITOKEN_LIST_DATA",
//   data
// });

const isFetchingData = data => ({
  type: "IS_FETCHING_DATA",
  data
});


export const mutation = {
  // setApiTokenList,
  setActiveApiToken,
  // setApiTokenListData,
  // removeApiTokenFromList,
  // setUpdateApiTokenListData,
  isFetchingData
};

import { Api } from "./config/request";
import standardSlugify from "standard-slugify";
// import { Iconv } from  "iconv";
// var Iconv  = require('iconv').Iconv;
// var iconv = new Iconv('UTF-8', 'ASCII//TRANSLIT//IGNORE');

export const getEventList =(city, distance, topevents, categories=null, tags=null, max = 12, page = 1) => {
  const params = {
    page: page,
    perPage: max,
    topevents: topevents?topevents:false,
    city: city?city:"Nice,France",
    distance: distance?distance:80,
    categories: categories,
    tags: tags
    // when: 'month'
    // populate: ['categories']
    // populate: ['pictures', 'categories']
  }
  return Api.getRequest("/TJapi/event/search", params);
};

export const getEventById =id => {
  return Api.getRequest(`/event/${id}`);
};

export const computeReferencingString = (eventObject) => {
    let referencing = '/sortir-';
    if (eventObject.coordinate) {
      let city = eventObject.coordinate.address.replace(/^(.*,? )*[0-9]*,?\s([^,]*).*$/g, '$2');
      if (city.indexOf(',') > -1) {
        city = city.replace(/^([^,]*),.*$/g, '$1');
      }
      // referencing += city + '-';
      referencing += city;
    }
    // referencing += eventObject.title;
    if (eventObject.categories) {
      eventObject.categories.forEach(aCategory => {
        referencing += '-' + aCategory.name;
      });
    }
    let idxTag = 0;
    if (eventObject.tags) {
      eventObject.tags.forEach(aTag => {
        if (idxTag < 3) {
          referencing += '-' + aTag.name.replace('_', '-');
          idxTag += 1;
        }
      });
    }
    return referencing;
}

export const computeUTM = (eventObject) => {
  return new Promise((resolve, reject) => {
    Api.getRequest('/auth/validate_token')
    .then(res => {
      // console.log("TOKEN INFO", res);
      let utmString = '?utm_source=' + standardSlugify(res.user.companyName ? res.user.companyName : res.user.companyname)
      + '&utm_medium=iframe&utm_campaign=' + standardSlugify(eventObject.title)
      + '&appId=' + res.user.appId;
      resolve(utmString);
    })
    .catch(err => {
      resolve('');
    })

  })
}


const Event = {
    getEventList ,
    getEventById,
    computeReferencingString,
    computeUTM,
    // createEvent,
    // updateEventById,
    // deleteEventById
};

export default Event;

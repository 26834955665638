import { Api } from "./config/request";

export const getTagList =(max = 12, page = 1) => {
  const params = {
    page: page,
    perPage: max
  }
  return Api.getRequest("/TJapi/tag", params);
};
export const searchTag =(tagName, max = 12, page = 1) => {
  const params = {
    page: page,
    perPage: max,
    tag: tagName
  }
  return Api.getRequest("/TJapi/tag/search", params);
};
// export const getTagById =id => {
//   return Api.getRequest(`${id}`);
// };
// export const createTag = payload => {
//     return Api.postRequest("",payload);
// };
//   export const updateTagById = (id,payload)  => {
//     return Api.putRequest(`${id}`,payload);
// };
//   export const deleteTagById = id => {
//     return Api.deleteRequest(`${id}`);
// };

const Tag = {
    getTagList ,
    searchTag,
    // getTagById,
    // createTag,
    // updateTagById,
    // deleteTagById
};

export default Tag;

// import { ErrorMessage } from "../../services/errorMessage";
import { mutation } from "./mutations";

const handleError = err => {
  console.error("Error in errorMessage action : ", err);
};

// Actions
// export const getErrorMessageListRequest = () => async dispatch => {
//   try {
//     dispatch(mutation.isFetchingData(true))
//     let result = await ErrorMessage.getErrorMessageList();
//     if (result) {
//       dispatch(mutation.setErrorMessageList(result ? result : []));
//       dispatch(mutation.isFetchingData(false))
//       return result;
//     }
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const createErrorMessageRequest = (payload) => async dispatch => {
//   try {
//     let result = await ErrorMessage.createErrorMessage(payload);
//     if (result) {
//       dispatch(mutation.setErrorMessageListData(result));
//       return result;
//     }
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const getErrorMessageByIdRequest = (id) => async dispatch => {
//   try {
//     let result = await ErrorMessage.getErrorMessageById(id);
//     dispatch(mutation.setActiveErrorMessage(result));
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const updateErrorMessageByIdRequest = (id,payload) => async dispatch => {
//   try {
//     let result = await ErrorMessage.updateErrorMessageById(id,payload);
//     if (result) {
//       dispatch(mutation.setUpdateErrorMessageListData(result));
//       return result;
//     }
//   } catch (err) {
//     handleError(err);
//   }
// };
//
// export const deleteErrorMessageByIdRequest = (id) => async dispatch => {
//   try {
//     dispatch(mutation.removeErrorMessageFromList(id));
//     let result = await ErrorMessage.deleteErrorMessageById(id);
//   } catch (err) {
//     handleError(err);
//   }
// };

export const setActiveErrorMessage = data => async dispatch => {
  try {
    dispatch(mutation.setActiveErrorMessage(data));
  } catch (err) {
    handleError(err);
  }
};
export const clearErrorMessage = () => async dispatch => {
  try {
    dispatch(mutation.clearErrorMessage());
  } catch (err) {
    handleError(err);
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { Provider } from 'react-redux'

for (let e of  document.getElementsByClassName('tj-frame'))
{
    let options;
    if (e.hasAttributes()) {
      options = {
        class: e.getAttribute("class"),
        app_secret: e.getAttribute("app_secret"),
        city: e.getAttribute("city"),
        dist: e.getAttribute("dist"),
        topevents: e.getAttribute("topevents"),
        categories: e.getAttribute("categories"),
        tags: e.getAttribute("tags"),
        type: e.getAttribute("type"),
      }
    }

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App style={e.style} options={options} />
            </Provider>
        </React.StrictMode>,
        e
    );
}

//
// document.getElementsByClassName('test').map(e => {
//     ReactDOM.render(
//         <React.StrictMode>
//             <Provider store={store}>
//                 <App style={document.getElementById('root').style}/>
//             </Provider>
//         </React.StrictMode>,
//         e
//     );
// })
//




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

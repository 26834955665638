import * as moment from 'moment';
import 'moment/locale/fr'

class DateService {

  static date(dateDebut, dateFin) {
    const today = new Date();
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    let fullDay = false;
    moment.locale('fr');
    if ( moment(dateDebut).hour() === 0 &&  moment(dateDebut).minutes() === 0) {
      if (dateFin) {
        if (moment(dateFin).hour() === 23 &&  moment(dateFin).minutes() === 59) {
          fullDay = true;
        } else {
          fullDay = true;
        }
      }
    }
    console.log("DAAAAATE HEURES", moment(dateDebut).format('LLLL'), moment(dateDebut).hour(), moment(dateFin).format('LLLL'), moment(dateFin).hour())
    if (moment(today).isSame(dateDebut, 'day')) {
      if (fullDay) {
        return ("Aujourd'hui")
      } else if (dateFin && false) {
        if (moment(dateDebut).isSame(dateFin, 'day')) {
          return moment(dateDebut).format('[Auj. de] HH[h]mm') + ' à ' + moment(dateFin).format('HH[h]mm');
        } else {
          return moment(dateDebut).format('[de Auj.] HH[h]mm') + ' au ' + moment(dateFin).format('ddd DD MMM [à] HH[h]mm');
        }
      } else {
        return moment(dateDebut).format('[Auj. à] HH[h]mm');
      }
    }

    else if (moment(tomorrow).isSame(dateDebut, 'day')) {
      if (fullDay) {
        return ("Demain")
      } else if (dateFin && false) {
        if (moment(dateDebut).isSame(dateFin, 'day')) {
          return 'Demain de' + moment(dateDebut).format('HH[h]mm') + ' à ' + moment(dateFin).format('HH[h]mm');
        } else {
          if (moment(dateFin).isSame(dateFin, 'year')) {
            return moment(dateDebut).format('[de Demain] HH[h]mm') + ' au ' + moment(dateFin).format('ddd DD MMM [à] HH[h]mm');
          } else {
            return moment(dateDebut).format('[de Demain] HH[h]mm') + ' au ' + moment(dateFin).format('ddd DD MMM YYYY [à] HH[h]mm');
          }

        }
      } else {
        return moment(dateDebut).format('[Demain. à] HH[h]mm');
      }

    } else {
      if (fullDay) {
        return (moment(dateDebut).format('[Le] DD MMM'))
      } else if (dateFin && false) {
        if (moment(dateDebut).isSame(dateFin, 'day')) {
          // if (moment(dateDebut).isSame(dateFin, 'year')){
          // 	return 'le ' + moment(dateDebut).format('ddd DD MMM [de] HH[h]mm') + ' à ' + moment(dateFin).format('HH[h]mm');
          // } else {
          return 'le ' + moment(dateDebut).format('ddd DD MMM YYYY [de] HH[h]mm') + ' à ' + moment(dateFin).format('HH[h]mm');
          // }
        } else {
          // if (moment(dateDebut).isSame(dateFin, 'year')){
          // 	return 'du ' + moment(dateDebut).format('ddd DD MMM HH[h]mm') + ' au ' + moment(dateFin).format('ddd DD MMM [à] HH[h]mm');
          // } else {
          return 'du ' + moment(dateDebut).format('ddd DD MMM YYYY HH[h]mm') + ' au ' + moment(dateFin).format('ddd DD MMM YYYY [à] HH[h]mm');
          // }
        }
      } else {
        return moment(dateDebut).format('[Le] DD MMM [à] HH[h]mm');
      }

    }

  }

}
export default DateService;
